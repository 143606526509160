.ProgressBar {
	margin: 4px;
}

.progress {
	display: inline-block;
	width: calc(100% - 140px);
	height: 40px;
	vertical-align: bottom;
}

.progress-bar {
	height: 100%;
}

/* Paul wanted cats on the progress bar */
/*.progress-bar-striped {*/
/*	background-image: url("http://pngimg.com/uploads/cat/cat_PNG50514.png");*/
/*	background-size: 40px 40px;*/
/*	background-blend-mode: hard-light;*/
/*}*/

/*@keyframes progress-bar-stripes {*/
/*	0% {*/
/*		background-position: 40px 0;*/
/*	}*/
/*}*/

.ProgressBars {
	align-items: space-around;
	display: flex;
	flex-direction: column;
	margin: 16px;
}

.ProgressBars .barData {
	display: inline-block;
	width: 140px;
	text-align: left;
	padding-left: 5px;
	line-height: 40px;
	vertical-align: bottom;
}
